import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './index.module.scss'
import Image from 'next/image'

const TableVisualization = ({ className }) => {
  return (
    <div className={classNames('flex flex-col gap-2 lg:min-w-80', className)}>
      <div
        className={classNames(
          'flex h-fit gap-x-1.5 lg:mx-0',
          styles.tableVisualization
        )}
      >
        <div className="flex basis-1/2 flex-col gap-y-1 text-12 lg:text-base ">
          <div className="bg-gray-75 lg:w-auto" />
          <div>Est. monthly payment</div>
          <div>Est. total cost</div>
          <div className="whitespace-pre">
            {'Est. time to get rid\nof debt'}
          </div>
        </div>
        <div className="flex basis-1/4 flex-col gap-y-1 border-l-0.75 border-dashed border-neutral-150 text-right text-14 lg:text-base">
          <div className="flex flex-col justify-center bg-gray-75 text-12 font-bold lg:text-base">
            Current
          </div>
          <div>$583</div>
          <div>$32.9k</div>
          <div className="text-right">4 yrs 9 mos</div>
        </div>
        <div className="flex basis-1/4 flex-col gap-y-1 border-l-0.75 border-blue-515 text-right text-14 font-bold lg:text-base">
          <div className="/n flex justify-center whitespace-pre text-12 lg:text-base">
            {'Freedom\nDebt Relief'}
          </div>
          <div>
            <p>$378</p>
          </div>
          <div>
            <p>$18.1k</p>
          </div>
          <div>
            <p>4 yrs</p>
          </div>
        </div>
      </div>
      <div className="flex items-start bg-green-50 p-2 text-sm md:items-center">
        <div className="relative h-5.5 w-5.5 shrink-0">
          <Image alt="dollar sign" src="/next-assets/dollar-sign.svg" fill />
        </div>
        <div className="ml-1">
          <span>Your estimated savings could be</span>
          <span className="hidden font-bold lg:inline">$205 per month!</span>
          <span className="font-bold lg:hidden">$205/month!</span>
        </div>
      </div>
    </div>
  )
}

TableVisualization.propTypes = {
  className: PropTypes.string,
}

TableVisualization.defauptProps = {
  className: '',
}

export default TableVisualization
