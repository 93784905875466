import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import styles from './index.module.scss'

import { useLeadId } from '../../../../common/lead-id'
import HyperLink from 'src/components/shared/HyperLink'
import { useRouter } from 'next/router'
import { buildQueryString } from '../../../../common/utils'

const ProgramWorks = ({ title, content, steps, footer }) => {
  const leadId = useLeadId()
  const { query: baseQuery } = useRouter()

  let query = useMemo(
    () => buildQueryString(baseQuery, leadId),
    [baseQuery, leadId]
  )

  return (
    <div
      className="w-content p-4 text-center text-sm font-normal leading-snug tracking-normal text-gray-900 lg:max-w-screen-lg lg:px-2 lg:pb-6 lg:pt-10"
      id="program-works"
    >
      <h2 className="mb-3 text-center text-2xl font-normal leading-tight tracking-normal text-blue-700 md:text-4xl md:leading-10">
        {title}
      </h2>
      {content.map((item, i) => (
        <p
          className="mb-4"
          key={`p_${i}`}
          dangerouslySetInnerHTML={{ __html: item }}
        />
      ))}
      <ol
        className={`${styles.stepContainer} m-auto mt-6 flex flex-col flex-wrap items-stretch justify-center md:flex-row`}
      >
        {steps.map((step, i) => (
          <li
            className={`${styles.stepContent} m-3 bg-gray-130 p-4 pb-8`}
            key={`p_${i}`}
          >
            <h3
              className="mt-2 text-xl font-semibold tracking-widest text-blue-600 lg:text-2xl"
              id={step.title}
            >
              {step.title}
            </h3>
            <p className="mt-3">{step.text}</p>
          </li>
        ))}
      </ol>
      {footer ? (
        <p className="mt-3">
          {footer.textFirstPart}
          <HyperLink
            childrenClassNames="cursor-pointer"
            href={`${footer.link.url}?${query}`}
            clickTracking
          >
            {footer.link.text}
          </HyperLink>
          {footer.textSecondPart}
        </p>
      ) : null}
    </div>
  )
}

ProgramWorks.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.array.isRequired,
  steps: PropTypes.array.isRequired,
  footer: PropTypes.any,
}

export default ProgramWorks
