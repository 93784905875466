import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from '../index.module.scss'

const ChartComponent = ({ className }) => {
  return (
    <div
      className={classNames(
        '-mt-7 h-66 w-full lg:-mt-5 lg:w-1/2 xl:-mt-7 xl:h-85 xl:w-132',
        styles.chart,
        className
      )}
    />
  )
}

ChartComponent.propTypes = {
  className: PropTypes.string,
}

ChartComponent.defauptProps = {
  className: '',
}

export default ChartComponent
