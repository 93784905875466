import { charts } from './consts'
import Img from '../../../../shared/Img'

const BarCharts = () => (
  <>
    <div className="flex flex-col">
      <div className="mb-4 flex flex-col items-center justify-center lg:gap-x-10 xl:flex-row">
        {charts.map((chart, index) => {
          return (
            <div
              key={index}
              className="w-max border-b border-gray-185 last:border-none even:pb-2.75 lg:border-none lg:even:mb-0"
            >
              <Img
                src={chart.src}
                alt={chart.alt}
                className="h-37 w-68.75 md:w-79.5"
              />
            </div>
          )
        })}
      </div>

      <div className="flex items-start justify-center bg-green-50 px-2 py-3.75 text-sm md:items-center xl:min-w-full xs:max-w-68.75 ssm:max-w-79.5">
        <Img
          alt="dollar sign"
          src="/next-assets/dollar-sign.svg"
          className="h-5.5 w-5.5 shrink-0"
        />
        <div className="ml-1">
          <span> Clients could </span>
          <span className="font-bold"> free up $205/mo (avg) </span>
          <span> compared to minimum debt payments. </span>
        </div>
      </div>
    </div>
  </>
)

export default BarCharts
