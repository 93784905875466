export const charts = [
  {
    src: '/next-assets/bar-chart-less.svg',
    alt: 'Bar Chart Minimum Payment',
  },
  {
    src: '/next-assets/bar-chart-reduce.svg',
    alt: 'Bar Chart Overall Debt',
  },
  {
    src: '/next-assets/bar-chart-faster.svg',
    alt: 'Bar Chart 9 Months Faster',
  },
]
