import React from 'react'
import PropTypes from 'prop-types'
import SvgIcon from '../../../../shared/SvgIcon'

const IconStrip = ({ iconStrip }) => {
  const { clients, noRisk, debtResolved } = iconStrip

  return (
    <div
      className="lg:w-content mx-auto flex justify-center bg-gray-150 md:opacity-80"
      id="hero-icons-strip"
    >
      <div className="flex flex-col p-5 text-s md:flex-row">
        <div className="mx-2 p-2">
          <div className="flex items-center">
            <div className="flex items-center justify-center rounded-full border border-blue-700 p-1">
              <SvgIcon name="profileMale" className="h-6 w-6 fill-blue-700" />
            </div>
            <p
              className="ml-1 text-blue-700"
              dangerouslySetInnerHTML={{ __html: clients }}
            />
          </div>
        </div>
        <div className="mx-2 p-2">
          <div className="flex items-center">
            <div className="flex items-center justify-center rounded-full border border-blue-700 p-1.5">
              <SvgIcon name="dollarSign" className="h-5 w-5 fill-blue-700" />
            </div>
            <p className="ml-1 text-blue-700">
              <span dangerouslySetInnerHTML={{ __html: noRisk }} />
            </p>
          </div>
        </div>
        <div className="mx-2 p-2">
          <div className="flex items-center">
            <div className="flex items-center justify-center rounded-full border border-blue-700 p-1.5">
              <SvgIcon name="checkmark" className="h-5 w-5 fill-blue-700" />
            </div>
            <p
              className="ml-1 text-blue-700"
              dangerouslySetInnerHTML={{ __html: debtResolved }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

IconStrip.propTypes = {
  iconStrip: PropTypes.object.isRequired,
}
export default IconStrip
