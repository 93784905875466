import React from 'react'
import PhoneNumber from '../../../shared/PhoneNumber'

const MoreQuestions = () => {
  return (
    <div
      className="pt-text-center w-content  mx-auto bg-gray-160 py-5"
      id="more-questions"
    >
      <p className="m-auto text-center text-2xl text-gray-510">
        Have more questions?&nbsp;
        <span className="font-black">Call us now: </span>
        <PhoneNumber
          href="tel:833-418-3210"
          text="833-418-3210"
          className="font-black text-red-700"
          isTrack={true}
          nav_link_section="Bottom section"
        />
      </p>
    </div>
  )
}

export default MoreQuestions
