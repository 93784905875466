import React from 'react'
import Img from '../../../../../src/components/shared/Img'
import PropTypes from 'prop-types'
import Hyperlink from '../../HyperLink'

const AsFeaturedIn = ({ items }) => {
  return (
    <div
      className="w-content gap-x-primary lg:px-38"
      data-testid="featuredIn"
      id="featuredIn"
    >
      <h3 className="w-full border-b border-gray-270 pb-2 text-center text-l font-bold leading-6.5 text-blue-700">
        As featured in
      </h3>
      <div
        className="mx-auto flex flex-wrap items-center justify-center md:w-9/12 lg:w-full lg:justify-between"
        data-testid="items"
      >
        {items?.map(({ index, logo, name, alt, link }) => (
          <Hyperlink
            href={link}
            key={index}
            clickTracking={true}
            alt={alt}
            childrenClassNames="w-1/2 pt-6 lg:w-auto"
          >
            <Img
              className="mx-auto h-24 w-36"
              dataTestId={name}
              src={logo}
              alt={alt}
            />
          </Hyperlink>
        ))}
      </div>
    </div>
  )
}

AsFeaturedIn.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      logo: PropTypes.string,
    })
  ),
}

export default React.memo(AsFeaturedIn)
